import './App.css';
import { cebian } from './cebian'
import { useState, useRef, useEffect } from 'react'
import { render } from "react-dom";
import autoAnimate from '@formkit/auto-animate'
import { motion } from "framer-motion"





function Ddd(){
  return  <div>cesdddddd</div>
}


function App() {




  return (
    <div className="App">
        <motion.div className='dong1'
        animate={{
          scale:[1,1.5,1.2,1,1],
          rotate:[0,0,180,360,-180,0],
          borderRadius: ["12px", "0%", "50%", "50%", "10px"],
          
        }}
        transition={{
          duration: 2,
          ease: "easeInOut",
          times: [0, 0.2, 0.5, 0.8, 1],
          repeat: Infinity,
          repeatDelay: 1
        }}

        ><img className="logo"  src="https://pic.imgdb.cn/item/670d47f0d29ded1a8c69a18e.png">
        </img></motion.div>
        
        <p className="text1"
        
        
        >游手好闲のBlog
        </p>
        <div className ="mao1"></div>
        <div className='new' id='root'></div>
        <motion.div className='donghua1' animate={{ x:200 }}
        whileHover={{ scale: 1.2 }} >
          
        </motion.div>
        <Ddd />

    </div>

  );
}
render(<cebian />, document.getElementById("root"));

export default App;
